import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Link from '../components/Link';

import PackageJson from '../../package.json';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: {eq: "icon.png"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            width: 32
            height: 32
          )
        }
      }
    }
  `);

  return (
    <header className="bg-gray-900 py-4 text-yellow-300">
      <div className="container-lg">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
          <div>
            <Link className="flex items-center block no-underline" to="/">
              <GatsbyImage image={getImage(data.logo)} alt="Minify JS logo" />
              <div className="ml-3">
                <span className="block tracking-wide font-display font-semibold">
                  Minify JS Online
                </span>
                {' '}
                <span className="text-gray-400 text-xs block">
                  {`v${PackageJson.version}`}
                </span>
              </div>
            </Link>
          </div>
          <div className="flex flex-col sm:flex-row mt-6 sm:mt-0">
            <div className="sm:mr-8">
              <Link className="no-underline tracking-wide font-bold" to="/benchmarks/">
                Benchmarks
              </Link>
            </div>
            <div className="mt-4 sm:mt-0 sm:mr-8">
              <Link className="no-underline tracking-wide font-bold" to="/tools-and-solutions/">
                Tools and Solutions
              </Link>
            </div>
            <div className="mt-4 sm:mt-0">
              <Link className="no-underline tracking-wide font-bold" to="/blog/">
                Blog
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
