exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-benchmarks-jsx": () => import("./../../../src/pages/benchmarks.jsx" /* webpackChunkName: "component---src-pages-benchmarks-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-tools-and-solutions-jsx": () => import("./../../../src/pages/tools-and-solutions.jsx" /* webpackChunkName: "component---src-pages-tools-and-solutions-jsx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-how-to-minify-javascript-code-with-gulp-and-terser-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/how-to-minify-javascript-code-with-gulp-and-terser.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-how-to-minify-javascript-code-with-gulp-and-terser-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-how-to-set-up-webpack-to-minify-js-with-esbuild-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/how-to-set-up-webpack-to-minify-js-with-esbuild.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-how-to-set-up-webpack-to-minify-js-with-esbuild-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-how-to-set-up-webpack-to-minify-js-with-terser-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/how-to-set-up-webpack-to-minify-js-with-terser.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-how-to-set-up-webpack-to-minify-js-with-terser-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-introducing-configurable-options-in-minify-js-tool-v-1-2-0-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/introducing-configurable-options-in-minify-js-tool-v1-2-0.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-introducing-configurable-options-in-minify-js-tool-v-1-2-0-md" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-javascript-minification-online-tool-update-md": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/opt/build/repo/src/content/blog/javascript-minification-online-tool-update.md" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-content-blog-javascript-minification-online-tool-update-md" */)
}

