import React, { useState, useEffect } from 'react';
import Link from '../components/Link';
import cookie from 'react-cookies';

const ConsentBar = () => {
  const [ accepted, setAccepted ] = useState(true);

  useEffect(() => {
    setAccepted(cookie.load('cookie-accepted'));
  }, [setAccepted])

  const handleAccept = () => {
    cookie.save('gdpr-google-analytics', true);

    cookie.save('cookie-accepted', true);
    setAccepted(true);
  };

  return (
    <>
      {!accepted && (
        <div className="fixed bottom-0 w-full bg-gray-900 text-white shadow-xl">
          <div className="container-lg">
            <div className="py-4 flex items-center justify-between">
              <p className="text-md">
                By using Minify-JS.com you accept our use of cookies and agree to our
                {' '}
                <Link to="/privacy-policy/" className="text-yellow-300">
                  privacy policy
                </Link>.
              </p>
              <button
                className="btn btn-sm btn-primary"
                onClick={() => handleAccept()}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConsentBar;
