import React from 'react';
import Link from '../components/Link';

const Footer = () => (
  <footer>
    <div className="container-lg">
      <div className="flex justify-between gap-4 border-t py-4">
        <div>
          <small className="text-xs">&copy; minify-js.com</small>
          {' '}
          <Link className="text-xs" to="/privacy-policy/">privacy policy</Link>
        </div>
        <div>
          <small className="text-xs">
            Developed by
          </small>
          {' '}
          <Link className="text-xs hover:underline" to="https://pratsko.dev/">Oleh Pratsko</Link>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
