import React from 'react';
import ConsentBar from './ConsentBar';
import Header from './Header';
import Footer from './Footer';
import PropTypes from '../utils/PropTypes';

const propTypes = {
    children: PropTypes.node,
};

const defaultProps = {
    children: null,
};

const Layout = ({ location, children }) => (
    <>
        <Header />
        <main>
            {children}
        </main>
        <ConsentBar />
        <Footer />
    </>
);

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
